@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: HKGrotesk-Light;
    src: url('./assets/fonts/HKGrotesk-Light.woff');
}
@font-face {
    font-family: HKGrotesk-Bold;
    src: url('./assets/fonts/HKGrotesk-Bold.woff');
}
*{
    font-family: HKGrotesk-Light !important;
}
.font-hkg-bold{
    font-family: HKGrotesk-Bold !important;
}
.uppy-Dashboard-inner{
    width: auto !important;
    height: auto !important;
    background-color: white !important;
}
.uppy-DashboardContent-bar{
    background-color: white !important;
}
[data-uppy-drag-drop-supported="true"] .uppy-Dashboard-AddFiles{
    padding: 3rem;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}
.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.rounded-xl-important{
    border-radius: 0.75rem !important;
}
.swal2-styled.swal2-confirm{
    color: green !important;
}
.swal2-styled.swal2-cancel{
    color: red !important;
}
.swal2-actions:not(.swal2-loading) .swal2-styled.swal2-confirm:hover {
    background-image: linear-gradient(rgba(33, 243, 163, 0.1), rgba(33, 243, 128, 0.1)) !important;
}
.swal2-actions:not(.swal2-loading) .swal2-styled.swal2-cancel:hover {
    background-image: linear-gradient(rgba(243, 33, 44, 0.1), rgba(243, 33, 44, 0.1)) !important;
}
.swal2-styled.swal2-confirm:focus{
    background-color: rgba(33, 243, 163, 0.3);
}
.swal2-styled.swal2-cancel:focus{
    background-color: rgba(243, 33, 44, 0.3);
}
.cat-flag {
    background-size: 300px 100px;
    background: linear-gradient(#FFDA44 11.11%, #D80027 11.11% ,#FFDA44 11.11%, #D80027 11.11%, #FFDA44 11.11%, #D80027 11.11%, #FFDA44 11.11%, #D80027 11.11%, #FFDA44 11.11%);
}
.css-1tape97:not(:last-of-type){
    border-right: 1px solid rgb(229, 231, 235) !important;
}

@media print {
    body {
      width: 300px;
      height: 300px;
    }
  }